@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}

:root {
  --main-color: #550c18;
  --second-color: #f1f1f1;
  --font-color: #feffff;
  --black-color: #17252a;
  --third-color: #d0ada7;
}

header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  padding: 0 2rem;
  margin: 10px 0px;
}

.n-logo img {
  height: 300px;
}

nav {
  width: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0px;
  border-radius: 20px;
}

nav a {
  display: inline-block;
  position: relative;
  color: var(--main-color);
  text-decoration: none;
  margin: 0px 20px;
  font-size: 18px;
  font-weight: 500;
}

nav a:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: var(--main-color);
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

nav a:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}
header .nav-btn {
  padding: 5px;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  color: var(--main-color);
  visibility: hidden;
  opacity: 0;
  font-size: 1.8rem;
}

.nav-btn1 {
  padding: 5px;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  color: var(--black-color);
  visibility: hidden;
  opacity: 0;
  font-size: 1.8rem;
}

.blink {
  animation: blinker 1s linear infinite;
  background-color: var(--main-color);
  color: var(--font-color);
  padding: 5px;
}

.blink:hover{
  animation-play-state: paused;
}

@keyframes blinker {
  50% {
    opacity: 0.2;
  }
}

header div,
nav {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 1500px) {
  header .nav-btn,
  .nav-btn1 {
    visibility: visible;
    opacity: 1;
  }

  header nav {
    position: fixed;
    top: -150vh;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    background-color: var(--second-color);
    transition: 0.5s;
    z-index: 9999;
  }

  header .responsive_nav {
    transform: translateY(150vh);
  }

  nav {
    border-radius: 0px;
  }

  nav .nav-close-btn {
    position: absolute;
    top: 2rem;
    right: 2rem;
  }

  nav a {
    font-size: 1.5rem;
    color: var(--main-color);
  }
}

@media (max-width: 500px) {
  .n-logo img {
    height: 200px;
  }
}

@media (max-width: 385px) {
  .n-logo img {
    height: 180px;
  }
}
