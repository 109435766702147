.socialmedia{
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: var(--font-color);
    padding: 20px;
    background-color: var(--main-color);
}

.s-content{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    flex: 1;

}

.s-content span{
    font-size: 40px;
    font-weight: 500;
}

.s-content p{
    font-size: 18px;
    font-weight: 500;
}

.s-icons{
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
}

.s-icons a{
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    font-size: 30px;
    margin: 0px 10px;
    color: var(--second-color);
    height: 50px;
    width: 50px;
    border-radius: 10px;
    border: 2px solid var(--second-color);
}

.s-icons a:hover {
    box-shadow: 0 0 7px var(--second-color), 0 0 15px var(--second-color);
}

@media (max-width: 550px) {
    .socialmedia{
        flex-direction: column;
    }
    .s-content{
        margin-bottom: 20px;
    }
}