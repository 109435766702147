.testimonials{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0px 20px;
}

.testo{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
    margin: 20px;
}

.testo video{
    width: 100%;
    border-radius: 20px;
    border: none;
}

@media (max-width: 550px) {
    .testo{
        grid-template-columns: 1fr;
    }
}