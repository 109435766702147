.lectures {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;

  width: 100%;
  
}

.video-list {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;

}

.video-item {
  margin: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  text-align: left;
  width: 100%;

}

.ivideo{
    width: 100%;
    height: 315px;
}

.video-item h3 {
  font-size: 1.2em;
  margin: 0 0 10px;
}

@media (max-width: 610px) {
    
}