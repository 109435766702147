/* Adjust the styles according to your needs */
.carousel .slide img {
    width: 100%;
    height: auto;
  }
  
  .legend {
    color: #fff;
    font-size: 20px;
    text-align: center;
    padding: 10px;
    background: rgba(0, 0, 0, 0.5);
  }