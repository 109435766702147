.results{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
}

.result{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    place-items: center;
    width: 80%;
    gap: 100px;
    margin: 20px 0px;
}

.student{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.s-img img{
    height: 200px;
    border-radius: 50%;
}

.student span{
    font-size: 30px;
    font-weight: 500;
}

.student p{
    font-size: 20px;
}

@media (max-width: 1480px) {
    .result{
        grid-template-columns: repeat(3, 1fr);
    }    
}

@media (max-width: 1130px) {
    .result{
        grid-template-columns: repeat(2, 1fr);
    }    
}

@media (max-width: 720px) {
    .result{
        grid-template-columns: repeat(1, 1fr);
    }    
}