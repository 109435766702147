/* BackToTopButton.css */
.back-to-top-button {
    display: block;
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 99;
    border: none;
    outline: none;
    background-color: var(--main-color);
    color: var(--font-color);
    cursor: pointer;
    padding: 15px;
    border-radius: 50%;
}

.back-to-top-button:hover {
    background-color: #781b2b;
}
