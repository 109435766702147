.home {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.whyus {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--main-color);
  width: 100%;
  color: var(--font-color);
}

.us {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  width: 80%;
  padding: 20px;
}

.years {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 20px;
}

.years h2 {
  display: flex;
  font-size: 40px;
}

.years span {
  font-size: 24px;
}

.addmission{
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 200px;
  width: 100%;
  padding: 20px;
}

.addmission h2{
  font-size: 30px;
}

.addmission a{
  color: var(--main-color);
  text-decoration: none;
  padding: 20px;
  margin: 20px;
  border: 2px solid var(--main-color);
  border-radius: 20px;
  font-size: 30px;
}

.addmission a:hover{
  color: var(--font-color);
  background-color: var(--main-color);
}

.open{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: var(--main-color);
}

.founder {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 80%;
  padding: 20px 0px;
}

.line {
  height: 3px;
  width: 10%;
  background-color: var(--main-color);
}

.f-heading {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
  padding: 10px;
}

.f-heading h1 {
  font-size: 40px;
  margin: 0px 10px;
}

.founder-info {
  display: flex;
  justify-content: center;
  align-items: center;
}

.f-info {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex: 1;
  text-align: justify;
  padding: 10px;
}

.f-info h3 {
  font-size: 40px;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.f-info span {
  font-size: 24px;
}

.f-info p {
  font-size: 20px;
}

.founder-img {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.founder-img img {
  height: 500px;
}

.courses {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 80%;
  padding: 20px 0px;
}

.all-courses {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.c-course {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px;
  margin: 10px 0px;
}

.c-courses {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  width: 350px;
  height: 500px;
  padding: 0px 0px 10px 0px;
}

.c-courses img {
  height: 300px;
  width: 350px;
}

.c-courses p {
  text-align: justify;
}
.c-courses span {
  font-size: 22px;
}
.c-courses a {
  text-decoration: none;
  background-color: var(--main-color);
  padding: 10px;
  color: #fff;
}
.c-courses a:hover {
  background-color: #7b1728;
}

.gallery {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 80%;
  padding: 20px 0px;
}
.line1 {
  height: 3px;
  width: 5%;
  background-color: var(--main-color);
}

.photo-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  margin-top: 10px;
  width: 90%;
}

.photo {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
}

.photo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: 0.5s ease-in-out;
}

.photo img:hover {
  transform: scale(1.1);
}

.photo-grid:first-child {
  grid-column: span 2;
  grid-row: span 2;
}
.photo-grid:nth-child(2) {
  grid-column: span 1;
  grid-row: span 1;
}
.photo-grid:nth-child(3) {
  grid-column: span 1;
  grid-row: span 1;
}
.photo-grid:nth-child(4) {
  grid-column: span 1;
  grid-row: span 1;
}
.photo-grid:nth-child(5) {
  grid-column: span 1;
  grid-row: span 1;
}
.photo-grid:nth-child(6) {
  grid-column: span 1;
  grid-row: span 1;
}
.photo-grid:nth-child(7) {
  grid-column: span 2;
  grid-row: span 1;
}
.photo-grid:nth-child(8) {
  grid-column: span 1;
  grid-row: span 1;
}
.photo-grid:nth-child(9) {
  grid-column: span 1;
  grid-row: span 1;
}
.photo-grid:last-child {
  grid-column: span 2;
  grid-row: span 1;
}

.numbers {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 80%;
  padding: 20px 0px;
}

.achievements {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20px;
}

.a-main {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  text-align: center;
  background-color: var(--second-color);
  border-right: 2px solid #000;
  border-bottom: 2px solid #000000;
  border-radius: 0px 20px 20px 20px;
  color: var(--black-color);
  height: 150px;
  width: 250px;
  padding: 0px 10px;
}

.a-main span {
  font-size: 30px;
}
.a-main p {
  font-size: 20px;
}

.contact {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: url(../../Assets/back.jpg);
  background-size: cover;
}

.map-form {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
  width: 80%;
  border-radius: 20px;
  padding: 20px;
  gap: 20px;
}

.map {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
  width: 100%;
}

.form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex: 1;
  padding: 10px 20px;
  background-color: var(--second-color);
  border-radius: 20px;
}

.form-heading {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.form-heading p {
  color: var(--main-color);
  font-size: 30px;
  font-weight: 600;
}

form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.form-sec input::placeholder {
  color: var(--black-color);
}

.form-sec input {
  margin: 10px 20px 10px 0px;
  padding: 20px;
  height: 60px;
  width: 95%;
  background: var(--second-color);
  border: 2px solid var(--main-color);
  color: var(--main-color);
  font-size: 18px;
  border-radius: 5px;
}

.form-button-btn button {
  border: 2px solid var(--main-color);
  background: var(--main-color);
  color: var(--font-color);
  font-size: 18px;
  height: 50px;
  cursor: pointer;
  border-radius: 5px;
  padding: 10px;
}

.form-button-btn button:hover {
  background: none;
  color: var(--main-color);
}

@media (max-width: 1050px) {
  .addmission{
    flex-direction: column;
    height: 300px;
  }
}
@media (max-width: 1150px) {
  .c-course {
    grid-template-columns: 1fr 1fr;
  }
  .achievements {
    grid-template-columns: 1fr 1fr;
  }
  .map-form {
    flex-direction: column;
  }
  .f-heading h1 {
    font-size: 30px;
  }
  .founder-info {
    flex-direction: column;
  }
  .us {
    grid-template-columns: 1fr 1fr;
  }
}



@media (max-width: 790px) {
  .c-course {
    grid-template-columns: 1fr;
  }
  .years span {
    font-size: 18px;
  }
}

@media (max-width: 550px) {
  .achievements {
    grid-template-columns: 1fr;
  }
  .years span {
    font-size: 15px;
  }
  .years h2 {
    font-size: 30px;
  }
  .addmission{
    height: 400px;
  }
}

@media (max-width: 500px) {
  .founder-img img {
    height: 400px;
  }
}

@media (max-width: 500px) {
  .founder-img img {
    height: 350px;
  }
  .us {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 400px) {
  .addmission a{
    font-size: 20px;
  }
}

