.about {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 30px;
}

.how {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
}

.howit {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  flex: 1;
  background-color: var(--font-color);
  height: 600px;
  padding: 20px;
  border-radius: 20px;
  margin: 0px 10px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 3px 5px 0 rgba(0, 0, 0, 0.19);
}

.howit span {
  font-size: 20px;
  font-weight: 500;
  color: var(--main-color);
}

.howit h1 {
  font-size: 50px;
  width: 50%;
}

.howit p {
  font-size: 18px;
  width: 100%;
  text-align: justify;
}

.howit-img {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex: 1;
  background-color: var(--font-color);
  height: 600px;
  padding: 20px;
  border-radius: 20px;
  margin: 0px 10px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 3px 5px 0 rgba(0, 0, 0, 0.19);
}

.h-img {
  height: 350px;
  width: 700px;
  border-radius: 20px;
  background-image: url(../../Assets/founder.jpg);
  background-size: cover;
  margin-bottom: 20px;
}

.results {
  display: grid;
  place-items: center;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  width: 80%;
}

.stats {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100px;
  width: 200px;
  padding: 20px;
  border-radius: 20px;
  background-color: #fafafa;
}

.stats h2 {
  display: flex;
  font-size: 35px;
}

.faculty {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 90%;
  padding: 30px 0px;
  background-color: var(--font-color);
  margin: 20px 0px;
  border-radius: 20px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2), 0 3px 5px rgba(0, 0, 0, 0.19);
}

.line2 {
  height: 3px;
  width: 30%;
  background-color: var(--main-color);
}

.team {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 30px;
}

.members {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  height: 300px;
  width: 250px;
  border-radius: 20px;
  border-right: 2px solid var(--main-color);
  border-bottom: 2px solid var(--main-color);
}

@media (max-width: 1670px) {
  .howit p {
    font-size: 15px;
  }
  .team{
    grid-template-columns: 1fr 1fr 1fr;
  }
  .members{
    width: 300px;
  }
}

@media (max-width: 1460px) {
  .howit h1 {
    font-size: 50px;
    width: 80%;
  }
}

@media (max-width: 1420px) {
  .how{
    flex-direction: column;
  }
  .howit{
    width: 100%;
  }
  .howit-img{
    margin: 20px 0px;
    width: 100%;
    flex-direction: row;
  }
}

@media (max-width: 1150px) {
  .team{
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 900px) {
  .howit-img{
    flex-direction: column;
  }
  .h-img{
    width: 100%;
    
  }
}

@media (max-width: 770px) {
  .team{
    grid-template-columns: 1fr;
  }
}

@media (max-width: 600px) {
  .results {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 450px) {
  .howit h1 {
    font-size: 30px;
  }
  .howit p {
    font-size: 14px;
  }
}