.c-contact {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: var(--main-color);
  color: var(--font-color);
  padding: 30px;
}

.contact-info {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: var(--font-color);
  color: var(--main-color);
  width: 60%;
  padding: 20px;
  border-radius: 20px;
  margin: 20px 0px;
}

.c-name span {
  font-size: 40px;
  font-weight: 600;
}

.c-address {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  place-items: center;
  width: 100%;
}

.add {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 20px 0px;
}

.add p {
  font-size: 22px;
}

.map-info {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
}

#map {
  border-radius: 20px;
}

.social-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 0px;
  gap: 40px;
}

@media (max-width: 1300px) {
  .c-address {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 800px) {
  .map-info{
    flex-direction: column;
  }
}

@media (max-width: 666px) {
    .c-name span {
        font-size: 30px;
      }
      .add p {
        font-size: 18px;
      }
}

@media (max-width: 570px) {
    .contact-info{
        width: 100%;
    }
}

@media (max-width: 570px) {
    .c-name span {
        font-size: 25px;
      }
      .add p {
        font-size: 15px;
      }
}
