.Neet{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px 0px;
}

.neetheading{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 20px 0px;
}

.neetheading span{
    font-size: 40px;
    font-weight: 600;
    margin: 0px 10px;
}

.neet-modules{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    width: 100%;
    place-items: center;
    gap: 20px;
}

.module{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: var(--main-color);
    color: var(--font-color);
    padding: 0px 0px 10px 0px;
    border-radius: 15px;

}

.module p{
    font-size: 24px;
}

.module img{
    height: 400px;
    border-radius: 13px 13px 0px 0px;
}

.neetlist{
    display: flex;
    justify-content: center;
    align-items: center;
}

.neetlist ul{
    width: 50%;
}

.neetlist li{
    margin: 5px 0px;
    font-size: 20px;
    list-style: circle;
}

.contact-neet{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0px;
}

.contact-neet p{
    font-size: 24px;
    margin: 0px 20px;
}

.contact-neet a{
    text-decoration: none;
    color: var(--main-color);
    font-size: 30px;
    font-weight: 500;
}

.anvideo{
    width: 320px;
    height: 550px;
}

@media (max-width: 1250px) {
    .neet-modules{
    grid-template-columns: repeat(3, 1fr);
    }
    .neetlist ul{
        width: 70%;
    }
}

@media (max-width: 950px) {
    .neet-modules{
    grid-template-columns: repeat(2, 1fr);
    }
    .contact-neet{
        flex-direction: column;
    }
}

@media (max-width: 650px) {
    .neet-modules{
    grid-template-columns: repeat(1, 1fr);
    }
    .contact-neet{
        flex-direction: column;
    }
}