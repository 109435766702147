.footer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: var(--second-color);
    width: 100%;
    padding: 30px 0px;
    color: var(--black-color);
  }

  .ls-logo img{
    height: 100px;
  }
  
  .f-line {
    height: 3px;
    width: 80%;
    background-color: var(--main-color);
  }
  
  .f-content {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 150px;
    padding: 30px 10px;
  }
  
  .logo-social {
    display: flex;
    justify-content: start;
    align-items: start;
    flex-direction: column;
    flex: 2;
    width: 400px;
  }

  .f-logo{
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  
  .logo-social span {
    font-size: 20px;
    color: var(--black-color);
    text-transform: uppercase;
    font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  }
  
  .social-tags {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .social-tags i {
    font-size: 30px;
    margin-right: 20px;
  }
  
  .newsletter {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 30px;
  }
  
  .newsletter-form {
    width: 500px;
    position: relative;
  }
  
  .newsletter input {
    margin: 0;
    padding: 0px 0px 0px 20px;
    height: 50px;
    width: 100%;
    background: none;
    border: 2px solid var(--black-color);
    color: var(--black-color);
    font-size: 18px;
  }
  
  .newsletter input::placeholder {
    color: var(--black-color);
  }
  
  .newsletter button {
    position: absolute;
    top: 0;
    right: 10px;
    z-index: 2;
    border: none;
    background: none;
    color: var(--black-color);
    font-size: 18px;
    height: 50px;
    cursor: pointer;
  }
  
  .f-tags {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    flex: 1;
  }
  
  .f-tags a {
    display: inline-block;
    position: relative;
    color: var(--black-color);
    text-decoration: none;
    margin: 20px 20px;
  }
  
  .f-tags a:after {
    content: "";
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: var(--black-color);
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }
  
  .f-tags a:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
  
  .getintouch {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    flex: 1;
  }
  
  .address {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: column;
    width: 300px;
    margin-top: 20px;
  }
  
  .address-heading i {
    font-size: 30px;
  }
  
  .actual-address {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
  }
  .actual-address p {
    text-align: justify;
  }
  .actual-address a {
    color: var(--black-color);
  }
  
  @media (max-width: 900px) {
    .f-content {
      flex-direction: column;
      gap: 40px;
    }
  }
  
  @media (max-width: 650px) {
    .newsletter-form {
      width: 400px;
    }
    .address-heading i {
      font-size: 20px;
    }
    .actual-address p {
      font-size: 10px;
    }
    .address-heading span {
      font-size: 12px;
    }
    .address {
      align-items: center;
      width: 200px;
    }
  }
  
  @media (max-width: 460px) {
    .address {
      width: 150px;
    }
    .newsletter-form {
      width: 300px;
    }
    .logo-social {
      width: 300px;
    }
    .a-heading span {
      font-size: 25px;
    }
    .copyright p {
      font-size: 10px;
    }
    .address {
      width: 300px;
    }
  }
  